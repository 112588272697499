import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../pages/login/login'
import RegisterLayout from '../pages/register/layout'
import CheckinPage from '../pages/login/checkin'
import RankPage from '../pages/login/rank'
import ErrorPage from '../pages/login/error'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginPage
    },
    {
        path: '*',
        name: 'error',
        //component: ErrorPage
        redirect: '/'
    },
    {
        path: '/24checkin/:id',
        name: 'checkin',
        component: CheckinPage
    },
    // {
    //     path: '/rank',
    //     name: 'rank',
    //     component: RankPage
    // },
    // {
    //     path: '/scorerank',
    //     name: 'scorerank',
    //     component: () => import('../pages/login/scorerank')
    // },
    {
        path: '/register',
        name: 'register',
        component: RegisterLayout,
        children: [
            {
                path: 'place',
                name: 'register.place',
                component: () => import('../pages/register/place')
            },
            {
                path: 'staff',
                name: 'register.staff',
                component: () => import('../pages/register/staff')
            },
            // {
            //     path: 'entourage',
            //     name: 'register.entourage',
            //     component: () => import('../pages/register/entourage')
            // },
            {
                path: 'info',
                name: 'register.info',
                component: () => import('../pages/register/info')
            },
            {
                path: 'information',
                name: 'register.information',
                component: () => import('../pages/register/information')
            },
            {
                path: 'result',
                name: 'register.result',
                component: () => import('../pages/register/result')
            },
            {
                path: 'rank',
                name: 'register.rank',
                component: () => import('../pages/register/rank')
            },
            // {
            //     path: 'steprank',
            //     name: 'register.steprank',
            //     component: () => import('../pages/register/steprank')
            // },
            // {
            //     path: 'photography',
            //     name: 'register.photography',
            //     component: () => import('../pages/register/photography')
            // },
            // {
            //     path: '/admin/album',
            //     name: 'admin.album',
            //     component: () => import('../pages/admin/album')
            // },

        ]
    },
    {
        path: '/year',
        name: 'register.year',
        component: () => import('../pages/register/year')
    },
]

const router = new VueRouter({
    routes
})

export default router
