<template>
    <div class="checkin">
        <!--
        <img class="checkin_img_logo" src="../../image/logo.png" alt="">
        -->
        <div class="checkin_content">
            <p class="checkin_content_title">签到页面</p>
            <div class="checkin_content_body">
                <img :src='imgUrl' alt="">
                <span>所在公园：{{ place }}</span>
                <span>签到时间：7:30-10:30</span>
                <button @click="checkin">立即签到</button>
            </div>
        </div>
        <!--          登录窗口-->
        <div class="contentBC" v-show="isLoginShow">
            <div class="login_content">
                <span class="login_close" @click="closeLogin">X</span>
                <span class="login_content_title">员工登录</span>
                <div class="login_content_input">
                    <input type="text" v-model="staff.userId" placeholder="请输入7位员工号(i+6位数字)">
                    <span class="login_content_input_warn"
                          :class="valUseridFormat(staff.userId)?'success':'error'">
          {{ this.staff.userId.length === 0 ? '*' : (valUseridFormat(staff.userId) ? '√' : '请检查格式') }}
        </span>
                </div>
                <div class="login_content_input">
                    <input type="email" v-model="staff.email" placeholder="请输入邮箱">
                    <span class="login_content_input_warn"
                          :class="valEmailFormat(staff.email)?'success':'error'">
          {{ this.staff.email.length === 0 ? '*' : (valEmailFormat(staff.email) ? '√' : '请检查格式') }}
        </span>
                </div>
                <button class="login_content_button" @click="login">登录</button>
            </div>
        </div>
        <!--      提示窗口-->
        <Error v-if="isShow" @close="isShow=false" :message="message"></Error>
    </div>
</template>

<script>
import Error from '../../components/error'
import {mapActions, mapState} from "vuex";
import {valEmail, valUserid} from "../register/valid";

export default {
    name: "layout",
    components: {
        Error
    },
    data() {
        return {
            staff: {
                userId: '',
                email: '',
                source: 'checkin',
            },
            isLoginShow: false,
            loginRedirect: '',
            isShow: false,
            message: '',
            place: '',
            imgUrl: ''
        }
    },
    mounted() {
        this.getPlaceById(this.$route.params.id).then((place) => {
            if (place === undefined) {
                this.isShow = true;
                this.message = '请前往报名场地签到';
            }

            this.place = place === undefined ? undefined : place.name;
            this.imgUrl = place === undefined ? undefined : place.img;
        })
    },
    methods: {
        ...mapActions({
            doLogin: 'passport/login',
            checkLogin: 'passport/loadProfile',
            staffUseridEmail: 'register/staffUseridEmail',
            doCheckin: 'register/checkin',
            verifyCheckin: 'register/verifyCheckin',
            getPlaceById: 'register/getPlaceById'
        }),

        checkin() {
                this.isLoginShow = true
                this.loginCallback = () => {
                    this.doCheckin({place: this.place}).then(() => {
                        this.$router.replace({name: 'register.year'});
                        //已签到 报错
                    }).catch((e) => {
                        console.log(e);
                        if (e === '您已签到') {
                            this.$router.replace({name: 'register.year'});
                        } else {
                            this.isShow = true;
                            this.message = e;
                        }
                    })
               }
            // })

        },

        closeLogin() {
            this.isLoginShow = false
        },
        //登录
        login() {
            //判断登录
            //登录成功
            this.doLogin(this.staff).then(() => {
                this.loginCallback();
                //登录失败
            }).catch((err) => {
                this.isShow = true;
                this.message = err.message;
            });
        },
        //输入格式判断
        valUseridFormat(input) {
            return valUserid(input);
        },
        valEmailFormat(input) {
            return valEmail(input);
        },
    }

}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'
.error
    color $error

.success
    color green

.checkin
    width 100%
    height 400px
    background no-repeat url("../../image/layout_page_bac.jpg")
    background-size 750px
    background-color #00854a
    position relative
    .checkin_img_logo
        width 74px
        height 102px
        float left
        padding 31px 36px

    .checkin_content
        width 750px
        //height 938px
        height 100%
        padding-bottom 40px
        background #FFFFFF
        //box-shadow 0 0 40px 0 #C08560
        //box-shadow: 40px -40px 40px -40px $theme;
        border-radius 60px 60px 0 0
        position absolute
        top 260px
        .checkin_content_title
            height 47px
            font-size 48px
            font-family "source han sans cn"
            font-weight bold
            color $theme
            line-height 48px
            text-align center
            margin 40px 0 30px

        .checkin_content_body
            margin 0 38px
            padding-bottom 60px
            display flex
            flex-direction column
            align-items center

            span
                padding 30px 0 0
                font-size 36px
                font-family "source han sans cn"
                color $theme
                line-height 48px

            .time_limit
                font-size 30px
                color white
                padding 0

            img
                width 500px
                border-radius 30px
                box-sizing border-box
                background-color none
                padding 10px

            button
                buttonSet()
                margin-top 40px
                color white

//登录框
.contentBC
    position fixed
    background-color rgba(0, 0, 0, 0.6)
    top 0
    right 0
    bottom 0
    left 0
    display flex
    flex-direction column
    align-items center
    justify-content center
    z-index 9

    .login_content
        width 650px
        height 462px
        background-color white
        box-shadow 0 17px 35px 0 rgba(0, 41, 30, 0.08)
        border-radius 60px

        position absolute
        top 396px
        left 0
        right 0
        margin auto
        z-index 99

        display flex
        flex-direction column
        align-items center

        .login_close
            position absolute
            right 40px
            top 10px
            font-size 32px
            color $theme
            font-weight bold

        //background $gray

        .login_content_title
            titleSet()
            margin 40px 0 44px

        .login_content_input > input
            width 599px
            inputSet()

            margin-bottom 12px

        :placeholder
            inputPlaceholderSet()

        .login_content_input_warn
            font-size 30px
            line-height 86px
            position absolute
            right 60px

        //color $error

        .login_content_button
            buttonSet()
            margin 17px 0 33px
            color white
</style>
