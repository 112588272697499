<template>
  <!--登录-->
  <div class="login">
    <div class="login_info">
      <div class="login_info_title">报名时间：即日起至12月20日（周五）18:00截止</div>
      <span class="login_info_rule">活动规则</span>
      <ul class="login_info_item">
        <li>2024“健康徒步 走出快乐”健步走系列活动由SAP北京分公司工会举办，仅限截止至2024年10月1日前入职，目前在职的SAP北京分公司工会会员参加；</li>
        <li>签到将采用现场扫码、签字结合的办法，且签到数据以手签为准；</li>
        <li>活动当天自行前往报名地点完成签到，自行进入公园完成徒步健走。签到系统将于活动当天上午7点30分开启，10点30分关闭，早于或晚于签到时间的成绩将视为无效，请您合理安排参加时间；</li>
        <li>建议随身携带身份证，以备入园检查；</li>
        <li>健步走活动将作为长期健身项目持续举行，暂定在每月第三周的周六或周日举办，具体时间请以每期活动报名邀请为准；</li>
        <li>参加活动即视为授权工会对会员及家人活动现场照片进行保存、编辑、发布、展示；</li>
        <li>由于SAP公司已为员工购买补充商业保险，因此本次活动将不再额外配置保险项目。如您携带亲友，请妥善看顾，本次活动不承担任何意外责任；</li>
        <li>登记成功后，您将收到管理员发送的邮件确认信息，若未收到登记成功的邮件确认，或者登记当中遇到什么问题，请发邮件至 <a href="mailto:leqiliao@imprforest.com">leqiliao@imprforest.com</a> 信箱咨询；</li>
        <li>如您对活动有更多的想法或建议，希望您能通过联络员等渠道反馈，我们将认真聆听及考虑您的想法及感受，以期为大家提供更好的活动体验。</li>
        <li>本次活动的最终解释权归思爱普（中国）有限公司北京分公司工会所有。</li>
      </ul>
      <div class="login_bac"></div>
    </div>

      <div class="login_content">
        <span class="login_content_title">员工登录</span>
        <div class="login_content_input">
          <input type="text" v-model="staff.userId" placeholder="请输入7位员工号(i+6位数字)">
          <span class="login_content_input_warn"
                :class="valUseridFormat(staff.userId)?'success':'error'">
          {{ this.staff.userId.length===0?'*':(valUseridFormat(staff.userId)?'√':'请检查格式')}}
        </span>
        </div>
        <div class="login_content_input">
          <input type="email" v-model="staff.email" placeholder="请输入邮箱">
          <span class="login_content_input_warn"
                :class="valEmailFormat(staff.email)?'success':'error'">
          {{ this.staff.email.length===0?'*':(valEmailFormat(staff.email)?'√':'请检查格式')}}
        </span>
        </div>
        <button class="login_content_button" @click="login">登录</button>
      </div>


      <Error v-if="isShow" @close="isShow=false" :message="message"></Error>

  </div>
</template>

<script>
import Error from '../../components/error'
import {mapActions} from 'vuex'
import {valUserid,valEmail} from "../register/valid";
export default {
  name: "login",
  components:{
    Error
  },
  data(){
    return{
      staff:{
        userId:'',
        email:'',
      },
      isShow:false,
      message:''
    }
  },
  methods:{
    ...mapActions({
      doLogin: 'passport/login',
      staffUseridEmail:'register/staffUseridEmail',
    }),
    login(){
      this.doLogin(this.staff).then(()=>{
        this.staffUseridEmail(this.staff)
        this.$router.replace({name:'register.place'})
      }).catch((err)=>{
        if(err.message == '您已经报过名'){
          this.$router.replace({name:'register.information'})
        }else {
          this.isShow = true
          console.log(err);
          this.message = err.message
        }

      });
    },

    //输入格式判断
    valUseridFormat(input){
      return valUserid(input);
    },
    valEmailFormat(input){
      return valEmail(input);
    },
  }
}

</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'
.error
  color $error
.success
  color green

.login
  width 750px
  height 1900px
  background no-repeat url("../../image/login_page_bac0.jpg")
  background-size 750px
  //background-color:rgba(31, 37, 31,0.6);

  //报名规则说明
  .login_info
    width 750px
    position absolute
    top 1280px
    background rgba(0,0,0,0.7)
    border-radius 60px 60px 0 0
    color white
    font-family "source han sans cn"
    .login_info_title
      color $theme
      text-align center
      margin-top 60px
      font-size 24px
      font-weight bold
      display block
      line-height 40px
    .login_info_rule
      display block
      font-size 24px
      margin 10px 0
      text-align center
    .login_info_item
      padding 0 40px 40px 60px
      margin 0
      font-size 24px
      font-weight lighter
      li
        word-break  break-all
        list-style none
        text-align justify
        line-height 34px
        padding-bottom 10px
      li::before
           content "\02022"
           margin-left -20px
           margin-right 8px
      a
        color white
    .login_bac
      width 100%
      height 1200px
      position absolute
      bottom 0
      z-index -1
      background-color #2faee5
  //登录框
  .login_content
    width 650px
    height 462px
    background-color white
    box-shadow 0 17px 35px 0 rgba(0, 41, 30, 0.08)
    border-radius 60px
    position absolute
    top 750px
    left 0
    right 0
    margin auto
    z-index 99
    display flex
    flex-direction column
    align-items center
    .login_content_title
      titleSet()
      margin 40px 0 44px
    .login_content_input>input
      width 599px
      inputSet()

      margin-bottom 12px
    input::placeholder
      inputPlaceholderSet()
    .login_content_input_warn
      font-size 30px
      line-height 86px
      position absolute
      right 60px
      //color $error
    .login_content_button
      buttonSet()
      margin 17px 0 33px
      color white


</style>
